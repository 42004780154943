<template>
  <!-- eslint-disable max-len -->
  <div
    id="presentersFadeUp"
  >
    <div
      data-aos="fade-up"
      data-aos-anchor="#presentersFadeUp"
      data-aos-duration="500"
    >
      <h2 id="presenters" class="text-uppercase text-center mt-5">
        presenters
      </h2>
      <div class="row mb-0 mb-lg-7">
        <div class="col-12 col-lg-6 offset-0 offset-lg-3">
          <!-- presenter collapse toggler -->
          <a
          id="presenterCollapse0"
          class="cursor-pointer text-white nav-toggler-icon
          text-decoration-none
          d-flex justify-content-between align-items-start mt-4 mt-lg-5"
          @click.prevent="togglepresenterCollapse(0)"
          >
            <span class="h3 text-uppercase mt-material-icon"
              :class="selectedIndex === 0 ? 'text-secondary' : ''"
            >
              Academy of Visual Arts, Hong Kong Baptist University, Hong Kong
            </span>
            <i
              class="material-icons md-36 nav-toggler-icon"
              :class="selectedIndex === 0 ? 'rotate text-secondary' : ''"
            >
              add
            </i>
          </a>
          <!-- presenter collapse toggler -->
          <!-- presenter collapse content -->
          <div
            class="collapse"
            id="presenterCollapse0"
            ref="presenterCollapse0"
          >
            <div class="en">
              <p>
                The Academy of Visual Arts (AVA) is the first university academy of its kind in Hong Kong, providing professional visual arts undergraduate, postgraduate and research degree programmes linked to international exchange and the fast-growing creative industries.
              </p>

              <p>
                AVA is committed to nurturing creative and professional talent of international renown and strives to provide the very best in visual arts education, combining studio-based research, critical and historical studies and sustainable service for the cultural and creative sectors as well as for the community at large.
              </p>
            </div>
            <div class="hk">
              <p>
                視覺藝術院是香港第一所提供同類型教育的大學學院，開辦與專業視覺藝術相關的學士、修課式研究生及研究式研究生課程，放眼國際交流與當代藝術發展的趨勢，並接軌蓬勃發展的創意產業。學院透過優秀教學，致力培養蜚聲國際的創意及專業人才。
              </p>
              <p>
                身為香港同類教育的先驅，視覺藝術院致力於相關範疇提供最佳教育，專注以工作室為本的視覺藝術當代課題研究，以及持續服務文化創意產業以至社區的理念。
              </p>
            </div>
            <a href="https://ava.hkbu.edu.hk/en" target="_blank"
              class="text-uppercase text-decoration-none d-flex align-items-center"
            >
            website
            <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
            </a>
          </div>
          <!-- presenter collapse content -->

          <!-- presenter collapse toggler -->
          <a
          id="presenterCollapse1"
          class="cursor-pointer text-white nav-toggler-icon
          text-decoration-none
          d-flex justify-content-between align-items-start mt-4 mt-lg-5"
          @click.prevent="togglepresenterCollapse(1)"
          >
            <span class="h3 text-uppercase mt-material-icon"
              :class="selectedIndex === 1 ? 'text-secondary' : ''"
            >
              Videotage, Hong Kong
            </span>
            <i
              class="material-icons md-36 nav-toggler-icon"
              :class="selectedIndex === 1 ? 'rotate text-secondary' : ''"
            >
              add
            </i>
          </a>
          <!-- presenter collapse toggler -->
          <!-- presenter collapse content -->
          <div
            class="collapse"
            id="presenterCollapse1"
            ref="presenterCollapse1"
          >
            <div class="en">
              <p>
                Founded in 1986, Videotage has evolved from an artist-run collective to an influential network, supporting creative use of media art to explore, investigate and connect with issues that are of significant social, cultural, and historical value. Videotage is a leading Hong Kong-based non-profit organization specializing in the promotion, presentation, creation and preservation of new media art across all languages, shapes and forms.
              </p>

              <p>
                Dedicated to nurturing emerging media artists and developing the local media arts community, Videotage has organized numerous media arts events and programs while developing an extensive offline and online video art archive (VMAC).
              </p>
            </div>
            <div class="hk">
              <p>
                錄映太奇以香港為家，乃是一所非牟利藝術團體，專門推廣、創作並保存橫跨不同語言、形式和媒介的錄像及新媒體藝術作品。
              </p>
              <p>
                自1986年成立而來，錄映太奇已從最初的錄像藝術家聚會，日漸發展成為今時今日的國際媒體藝術網絡，鼓勵媒體藝術家透過其跨領域的藝術創作和交流平台去探索、研究並關注社會上各項與民生、文化及歷史息息相關的重要議題。錄映太奇致力培育新晉媒體藝術家和發展當地媒體藝術社區，舉辦了眾多媒體藝術活動和節目，同時開發了廣泛的線下和在線媒體藝術文獻庫(VMAC)。
              </p>
            </div>
            <a href="http://videotage.org.hk/web/index.php" target="_blank"
              class="text-uppercase text-decoration-none d-flex align-items-center"
            >
            website
            <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
            </a>
          </div>
          <!-- presenter collapse content -->
          <!-- presenter collapse toggler -->
          <a
          id="presenterCollapse0"
          class="cursor-pointer text-white nav-toggler-icon
          text-decoration-none
          d-flex justify-content-between align-items-start mt-4 mt-lg-5"
          @click.prevent="togglepresenterCollapse(2)"
          >
            <span class="h3 text-uppercase mt-material-icon"
              :class="selectedIndex === 2 ? 'text-secondary' : ''"
            >
              1a Space, Hong Kong
            </span>
            <i
              class="material-icons md-36 nav-toggler-icon"
              :class="selectedIndex === 2 ? 'rotate text-secondary' : ''"
            >
              add
            </i>
          </a>
          <!-- presenter collapse toggler -->
          <!-- presenter collapse content -->
          <div
            class="collapse"
            id="presenterCollapse2"
            ref="presenterCollapse2"
          >
            <div class="en">
              <p>
                1a space, founded in 1998, is an independent, non-profit making contemporary visual art organization and art venue founded by a collective of Hong Kong artworkers. 1a space aims to promote the making, experimentation, exchange and dissemination of contemporary art both in Hong Kong and in the international arena.
              </p>

              <p>
                Over nearly two decades, 1a space has developed into one of Hong Kong’s leading contemporary art organizations. It has produced more than 130 exhibitions and activities, and has been active in international exchanges, cultural festivals, participatory community art, as well as art education and publication. The operation funding of 1a space has been supported by the grants and donations, while its administration cost is partially supported by the Hong Kong Arts Development Council.
              </p>
            </div>
            <div class="hk">
              <p>
                1a空間於1998年成立，由香港藝術家集體創辦。它是一個獨立及非牟利的當代視覺藝術組織和藝術場地。1a空間積極推動本地及國際當代藝壇的創作、實驗、交流以及傳播。經過多年來的努力，1a空間已發展成香港主要的當代視覺藝術機構之一。迄今，1a空間已製作及策劃超過一百三十個展覽和活動，當中包括國際交流、文化節、社區互動藝術、藝術教育、藝術賞析、藝術評論和出版。1a空間的營運開支來自各位的贊助及捐獻，而部分行政費用則由香港藝術發展局贊助。
              </p>
            </div>
            <a href="https://www.oneaspace.org.hk/" target="_blank"
              class="text-uppercase text-decoration-none d-flex align-items-center"
            >
            website
            <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
            </a>
          </div>
          <!-- presenter collapse content -->
          <!-- presenter collapse toggler -->
          <a
          id="presenterCollapse3"
          class="cursor-pointer text-white nav-toggler-icon
          text-decoration-none
          d-flex justify-content-between align-items-start mt-4 mt-lg-5"
          @click.prevent="togglepresenterCollapse(3)"
          >
            <span class="h3 text-uppercase mt-material-icon"
              :class="selectedIndex === 3 ? 'text-secondary' : ''"
            >
              Centre for Arts and Design, University of Macau, Macao
            </span>
            <i
              class="material-icons md-36 nav-toggler-icon"
              :class="selectedIndex === 3 ? 'rotate text-secondary' : ''"
            >
              add
            </i>
          </a>
          <!-- presenter collapse toggler -->
          <!-- presenter collapse content -->
          <div
            class="collapse"
            id="presenterCollapse3"
            ref="presenterCollapse3"
          >
            <div class="en">
              <p>
                Established in 2018, Centre for Arts and Design (CAD) at the University of Macau focuses on education, research and service in the fields of arts, design, digital media and new media.  CAD collaborates with the departments of Communication, History, and the Faculty of Education for MA and PhD programs in visual communication to cultivate talents in creative media for Macao and the Guangzhou-Hong Kong-Macao Greater Bay Area.  Through establishing a platform for international exchange and collaborations in interdisciplinary research projects, CAD endeavors to promote creativity in the fields of art, design, and creative media internationally.  While technologies and art are inseparably associated, it is our hope that the synthesis of new technology and art could be utilized to diversify Macao’s economy, enhancing the development of the cultural and creative industry, and to ensure a sustainable competitive advantage for Macao towards a world-class tourism and leisure center.
              </p>
            </div>
            <div class="hk">
              <p>
                澳門大學藝術設計中心成立於2018年，中心聚焦藝術、設計、數碼媒體和新媒體等視覺傳播領域的教育、研究和服務。中心與澳門大學傳播系、歷史系和教育學院等院系合作，開設視覺傳播碩士和博士學位課程，為澳門培養高端的藝術、設計和創意媒體人才。在研究方面，通過建構高水平的國際性交流合作平台和跨學科研究項目，在粵港澳大灣區和國際藝壇展示澳門的創意和實力。中心立足澳門，放眼世界，通過研究和教育，利用新科技與藝術設計的結合，推動澳門文化創意產業的發展，為促進澳門經濟的多元化發展和邁向世界級旅遊休閒中心提供可持續的文化藝術特色和競爭優勢。
              </p>
            </div>
            <a href="https://cad.fss.um.edu.mo" target="_blank"
              class="text-uppercase text-decoration-none d-flex align-items-center"
            >
            website
            <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
            </a>
          </div>
          <!-- presenter collapse content -->
          <!-- presenter collapse toggler -->
          <a
          id="presenterCollapse4"
          class="cursor-pointer text-white nav-toggler-icon
          text-decoration-none
          d-flex justify-content-between align-items-start mt-4 mt-lg-5"
          @click.prevent="togglepresenterCollapse(4)"
          >
            <span class="h3 text-uppercase mt-material-icon"
              :class="selectedIndex === 4 ? 'text-secondary' : ''"
            >
              Virtual Lab for Art Museum, School of Arts Administration and Education, Central Academy of Fine Arts, Beijing
            </span>
            <i
              class="material-icons md-36 nav-toggler-icon"
              :class="selectedIndex === 4 ? 'rotate text-secondary' : ''"
            >
              add
            </i>
          </a>
          <!-- presenter collapse toggler -->
          <!-- presenter collapse content -->
          <div
            class="collapse"
            id="presenterCollapse4"
            ref="presenterCollapse4"
          >
            <div class="en">
              <p>
                The Central Academy of Fine Arts (CAFA) (originally the National Art School in Beiping, found in 1918) is the only institution of higher education for fine arts under the administration of the Ministry of Education of the People’s Republic of China. The academy has eight specialty schools: School of Chinese Painting, School of Plastic Arts, School of Design, School of Architecture, School of Humanities, School of Urban Design, School of Experimental Art, and Institute of Arts Administration and Education. CAFA has become a leading academy in the field of high education for fine arts in China as well as plays an important role in the world-class fine arts schools.
              </p>
            </div>
            <div class="hk">
              <p>
                中央美術學院美術館虛擬實驗室，北京
              </p>
              <p>
                中央美術學院（原為北平國立美術學校，始建於1918年）是中華人民共和國教育部直屬的唯一一所高等美術學校。現設有八個專業院系：中國畫學院、造型學科基礎部、設計學院、建築學院、人文學院、城市設計學院、實驗藝術學院、（中法）藝術與設計管理學院。中國美術學院已成為我國高等美術教育的領軍院校，在世界一流的美術院校中佔有重要地位。
              </p>
            </div>
            <a href="https://www.cafa.edu.cn/en/" target="_blank"
              class="text-uppercase text-decoration-none d-flex align-items-center"
            >
            website
            <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
            </a>
          </div>
          <!-- presenter collapse content -->
          <!-- presenter collapse toggler -->
          <a
          id="presenterCollapse0"
          class="cursor-pointer text-white nav-toggler-icon
          text-decoration-none
          d-flex justify-content-between align-items-start mt-4 mt-lg-5"
          @click.prevent="togglepresenterCollapse(5)"
          >
            <span class="h3 text-uppercase mt-material-icon"
              :class="selectedIndex === 5 ? 'text-secondary' : ''"
            >
              Art and Design Department, University of California, Berkeley Extension, San Francisco
            </span>
            <i
              class="material-icons md-36 nav-toggler-icon"
              :class="selectedIndex === 5 ? 'rotate text-secondary' : ''"
            >
              add
            </i>
          </a>
          <!-- presenter collapse toggler -->
          <!-- presenter collapse content -->
          <div
            class="collapse"
            id="presenterCollapse5"
            ref="presenterCollapse5"
          >
            <div class="en">
              <p>
                UC Berkeley’s Department of Art and Design encourage risk and creativity; teach collaboration and compassion; join the conceptual with the pragmatic; bring international cultures into bracing dialogue. For these reasons alone, arts and design plays a critical role in higher education and will continue to do so. Berkeley Arts + Design features, fortifies, and mobilizes existing excellence in the arts and design at UC Berkeley, while fostering dynamic collaboration, innovation, and public access across all arts and design fields, on campus and in public life.
              </p>

              <p>
                UC Berkeley Extension is a school within the vibrant UC Berkeley campus and offers a wide range of programs that bring international students to Berkeley. UC Berkeley Extension—like all other UC Berkeley schools, colleges and departments—is accredited by the Western Association of Schools and Colleges (WASC).
              </p>
            </div>
            <div class="hk">
              <p>
                加利福尼亞大學伯克利分校藝術設計系
              </p>
              <p>
                加州大學伯克利分校藝術與設計系鼓勵冒險和創造；教導合作和共情；結合理想與務實；容納國際文化，構建振奮的對話。僅憑以上這些原因，藝術和設計在高等教育中起著至關重要的作用，我們將繼續秉持這樣的教育方針。伯克利藝術+設計突出、強化和調動了加州大學伯克利分校藝術和設計領域的現有優勢，同時促進了校園和公眾參與的公共生活的動態協作、創新和跨領域藝術與設計。
              </p>
              <p>
                加州大學伯克利分校是一所充滿活力的學校，提供各種各樣的項目，吸引國際學生到伯克利。加州大學伯克利分校和所有其他加州大學分校、學院和系一樣，是由西方學校及學院協會（WASC）認證的。
              </p>
            </div>
            <a href="https://extension.berkeley.edu" target="_blank"
              class="text-uppercase text-decoration-none d-flex align-items-center"
            >
            website
            <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
            </a>
          </div>
          <!-- presenter collapse content -->
          <!-- presenter collapse toggler -->
          <a
          id="presenterCollapse0"
          class="cursor-pointer text-white nav-toggler-icon
          text-decoration-none
          d-flex justify-content-between align-items-start mt-4 mt-lg-5"
          @click.prevent="togglepresenterCollapse(6)"
          >
            <span class="h3 text-uppercase mt-material-icon"
              :class="selectedIndex === 6 ? 'text-secondary' : ''"
            >
              Estonian Academy of Arts, Tallinn
            </span>
            <i
              class="material-icons md-36 nav-toggler-icon"
              :class="selectedIndex === 6 ? 'rotate text-secondary' : ''"
            >
              add
            </i>
          </a>
          <!-- presenter collapse toggler -->
          <!-- presenter collapse content -->
          <div
            class="collapse"
            id="presenterCollapse6"
            ref="presenterCollapse6"
          >
            <div class="en">
              <p>
                Estonian Academy of Arts, Tallinn
              </p>
              <p>
                Established in 1914, the Estonian Academy of Arts is the only public university in Estonia providing higher education in fine arts, design, architecture, media, visual studies, art history, and conservation.
              </p>
              <p>
                The aim of the Academy is to be in the midst of life happening. That is why attention is paid to society and the professional world in broad terms. In addition to the Erasmus programme, EKA has close ties and cooperation agreements with more than 100 international universities and many international networks and has entered into student and faculty exchange and cooperation contracts with ten internationally recognised art universities outside of the European Union. Regular and intense contacts are maintained with the world outside academia through exhibitions and publishing work, through organising many events, and in cooperation with businesses and public institutions.
              </p>
            </div>
            <div class="hk">
              <p>
                塔林愛沙尼亞藝術學院
              </p>
              <p>
                愛沙尼亞藝術學院成立於1914，是愛沙尼亞唯一一所提供美術、設計、建築、媒體、視覺研究、藝術史和保育學等高等教育的公立大學。
              </p>
              <p>
                學院的強調“活在當下”。這就是為什麼人們廣泛關注社會和專業領域。除了伊拉斯謨（Erasmus）項目外，EKA還與100多所國際大學和許多國際網絡建立了密切的聯繫和合作協議，並與歐盟以外的10所國際認可的藝術大學簽訂了師生交流與合作合同。學院也與學術界以外的世界保持定期和密切的聯繫，舉辦展覽、出版，組織活動，以及與企業和公共機構合作。
              </p>
            </div>
            <a href="https://www.artun.ee/en/home/" target="_blank"
              class="text-uppercase text-decoration-none d-flex align-items-center"
            >
            website
            <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
            </a>
          </div>
          <!-- presenter collapse content -->
          <!-- presenter collapse toggler -->
          <a
          id="presenterCollapse7"
          class="cursor-pointer text-white nav-toggler-icon
          text-decoration-none
          d-flex justify-content-between align-items-start mt-4 mt-lg-5"
          @click.prevent="togglepresenterCollapse(7)"
          >
            <span class="h3 text-uppercase mt-material-icon"
              :class="selectedIndex === 7 ? 'text-secondary' : ''"
            >
              Fine Arts Academy of Rome
            </span>
            <i
              class="material-icons md-36 nav-toggler-icon"
              :class="selectedIndex === 7 ? 'rotate text-secondary' : ''"
            >
              add
            </i>
          </a>
          <!-- presenter collapse toggler -->
          <!-- presenter collapse content -->
          <div
            class="collapse"
            id="presenterCollapse7"
            ref="presenterCollapse7"
          >
            <div class="en">
              <p>
                The Fine Arts Academy of Rome originates from the Academy of San Luca, founded at the end of the 16th century and which quickly became a prestigious meeting place for artists and, at the same time, a teaching model to be imitated.<br>
                In its long history, the didactic offer of the Academy of Rome has had a constant evolution, in line with the transformations of artistic research.
                Today, the 3500 students enrolled can attend the Department of Visual Arts, which includes the classical and research schools (Painting, Sculpture, Scenography, Decoration and Art Graphics), the Department of Communication and Art Didactics, and the Department of Artistic Design, which offers courses in video, photography, fashion and design. The relationship with the most prestigious cultural realities of Rome and the great international consideration, place it as one of the most important cultural centers of the city.
              </p>
            </div>
            <div class="hk">
              <p>
                Accademia di Belle Arti di Rome 起源於 Accademia di San Luca（聖路加學院），這是一個由畫家、雕塑家和建築師組成的協會，由 Girolamo Muziano 和 Federico Zuccari 於 16 世紀後期創立。 Scuola Libera del Nudo（免費裸體學校）自 1754 年起開放至今，主要教授寫生； 該名稱最初是 Regia Accademia di Belle Arti denominata di San Luca，及後改成 Istituto di Belle Arti，然後更名為 Accademia di Belle Arti di Roma沿用至今。
              </p>
            </div>
            <a href="https://abaroma.it/" target="_blank"
              class="text-uppercase text-decoration-none d-flex align-items-center"
            >
            website
            <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
            </a>
          </div>
          <!-- presenter collapse content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Collapse from 'bootstrap/js/dist/collapse';

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      presenterCollapse0: '',
      presenterCollapse1: '',
      presenterCollapse2: '',
      presenterCollapse3: '',
      presenterCollapse4: '',
      presenterCollapse5: '',
      presenterCollapse6: '',
      presenterCollapse7: '',
      selectedIndex: '',
      presenterOpened: false,
      scrollToBottom: false,
    };
  },
  methods: {
    initCollapse() {
      // for (let i = 0; i < 8; i += 1) {
      //   const str = `presenterCollapse${i}`;
      //   this[str] = new Collapse(this.$refs[str], {
      //     toggle: false,
      //   });
      // }
      this.presenterCollapse0 = new Collapse(this.$refs.presenterCollapse0, {
        toggle: false,
      });
      this.presenterCollapse1 = new Collapse(this.$refs.presenterCollapse1, {
        toggle: false,
      });
      this.presenterCollapse2 = new Collapse(this.$refs.presenterCollapse2, {
        toggle: false,
      });
      this.presenterCollapse3 = new Collapse(this.$refs.presenterCollapse3, {
        toggle: false,
      });
      this.presenterCollapse4 = new Collapse(this.$refs.presenterCollapse4, {
        toggle: false,
      });
      this.presenterCollapse5 = new Collapse(this.$refs.presenterCollapse5, {
        toggle: false,
      });
      this.presenterCollapse6 = new Collapse(this.$refs.presenterCollapse6, {
        toggle: false,
      });
      this.presenterCollapse7 = new Collapse(this.$refs.presenterCollapse7, {
        toggle: false,
      });
    },
    togglepresenterCollapse(index) {
      const toggleTarget = `presenterCollapse${index}`;
      console.log(toggleTarget);
      this[toggleTarget].toggle();
      this.presenterOpened = !this.presenterOpened;
      if (this.presenterOpened) {
        this.selectedIndex = index;
      } else {
        this.selectedIndex = '';
      }
    },
  },
  mounted() {
    this.initCollapse();
  },
};
</script>
