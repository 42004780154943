<template>
  <!-- eslint-disable max-len -->

  <transition
    @leave="leave"
  >
  <!-- v-if="!load" -->
    <Loading
      v-if="!load"
      :overflow="true"
    ></Loading>
  </transition>

  <!-- Main content -->
  <TopNav></TopNav>
  <div class="container-fluid mt-nav">
    <div
      data-aos="fade-up"
      data-aos-delay="1500"
      data-aos-duration="500"
    >
      <h1 class="text-center">about citya</h1>
    </div>
    <div
      data-aos="fade-up"
      data-aos-delay="1800"
      data-aos-duration="500"
    >
      <div class="text-center">
        <div class="mt-4">
          <router-link
          to="/about#about-citya"
          class="h2 text-white text-uppercase text-decoration-none text-center"
          >
            our mission
          </router-link>
        </div>
        <div class="mt-4">
          <router-link
          to="/about#presenters"
          class="h2 text-white text-uppercase text-decoration-none text-center"
          >
            presenters
          </router-link>
        </div>
        <div class="mt-4">
          <router-link
          to="/about#programmes"
          class="h2 text-white text-uppercase text-decoration-none text-center"
          >
            programmes
          </router-link>
        </div>
      </div>
    </div>
    <!-- about citya - our mission -->
    <div
      id="about-citya"
      ref="about en h4"
      class="mt-4 mt-lg-7"
      data-aos="fade-up"
      data-aos-delay="2200"
      data-aos-duration="500"
    >
      <div class="row">
        <div class="col-12 col-lg-6 offset-0 offset-lg-3">
          <p>
            <i>CITYA</i> will continue to happen every three years as a long-term project for an art exchange among cities through Art institutions and organizations, and will also explore to be a new attempt of International Art exhibition. <i>CITYA</i> is based upon the theme of <i>‘City as a medium’</i>, and revolves around the relationship between the city and art. It is conceived in accordance with the ideas of the social theorist Henri Lefebvre, who wrote of “the art of living in the city as a work of art” in his collection of essays entitled <i>Right to the City</i>, in which he also declares that “the future of Art is not artistic, but urban”<sup>1</sup> . Four years ago, several curators <sup>2</sup> began a discussion on the topic of "city as a medium", at a time when everyone was still able to move freely and globally. However since the pandemic, the concept has transformed now that everyone needs to stay both within their city and at home for the majority of the time.
          </p>
          <p>
            Art practices have rapidly shifted to digital platforms as a curatorial space since the onset of the pandemic. In this context, the purpose of art is not simply to beautify and decorate the city or serve as a memento, the city in itself is a “work” (of art), or oeuvre, which involves citizen’s right of participation (or public appropriation) as play. Lefebvre states: “to City people the urban centre is movement, the unpredictable, the possible, and encounters. For them it is either ‘Spontaneous theatre’ or nothing.” (172) <sup>3</sup>  However, due to the pandemic, most of our exchange and communication has shifted to the virtual space, both globally and locally, as we all become ‘global citizens’ living far more online than we had ever experienced before the pandemic. But at the same time, everyone still lives within a real physical space within their own city, despite the lock downs and limited freedom to travel.  While we all may act as ‘global citizens’ communicating with others virtually without spatial boundaries, this curatorial project questions how artists reinterpret the art of living in both their own actual city and the virtual city. What could the future of art entail when the very meaning of ‘city’ has been transformed after the pandemic?
          </p>
          <p>
            <i>CITYA</i> is a curatorial platform that involves a number of different cities across the globe, and invites artists to express their thoughts through their practices within the theme of ‘City as a medium’. It also explores how artists interpret the same theme in post-pandemic, and whilst confronting social injustice.
          </p>
          <p>
            In the digital world, there is no physical space but rather spatiality, and technology does not augment space, but only modulates the spatiality of space. <sup>4</sup> Through the platform of <i>CITYA</i>, we will attempt to further explore the interrelationship of spatiality between actual space and digital space, <i>CITYA</i> also investigates virtuality and physically curatorial practices and how they interrelate, by considering the exhibition methodologies and their effect on artistic responses. It also examines types of knowledge production and provides new perspectives and strategies in digital curation in both physical and virtual space.
          </p>
          <p>
            <i>CITYA</i>, will be held as a virtual international exchange, but at the same time participating artists, like local individuals, will be closely connected to their local environments. No matter where the artists are based, the location of the city they are living in, or their state of lock down, any environment has the potential to become an inspirational element for artist creation and exhibition making.
          </p>
          <p>
            The interrelationship of spatiality between virtual and actual cities will broaden the possibilities for artists to adopt different mediums for their art practices. <i>CITYA</i> will not be restricted to any one artform, and will include mediums which are digital-friendly, such as new media art, paintings, photography, and video art (performing art). <i>CITYA</i> will also be a platform to encourage curators and artists to include more traditional art forms usually showcased in physical spaces, such as sculptures and installations, in order to explore the contextualisation of knowledge production in terms of virtual and physical spatiality. We also attempt to examine the significance of modulating the typology of space or of making new sculptures or installations through digital Technologies.
          </p>
          <p>
            At the same time, any location within the city could become an exhibition or creative space for artists to showcase their work for this project, in order to support the idea of cities being connected via a sense of humanity working together in a global crisis through art. The project will map out new modes of connection and creation within the international art scene, among cities and artists, by considering the context and the methodologies for creating and showcasing their artworks under the influence of the spatial differences (spatiality) on both virtual platforms and in reality.
          </p>
          <p>
            Different contemporary art hubs and cities each present a unique set of unique cultural environment and human connections, and <i>CITYA</i> aims to explore the valuable landscapes of each to propose future prospects for new contemporary art scenes, both locally and globally.
          </p>
          <p>
            “Today, virtuality characterizes the quality of aesthetic experience under contemporary conditions. The range of practices encompassed by digital mediation has significantly influenced aesthetic experience, as they facilitate transactions between artefacts, modes of display, viewers and spatial experiences.”Vince Dzekan mentioned in Virtuality And The Art of Exhibition.<sup>5</sup>  The <i>CITYA</i> special platform will exist virtually and physically, and each of the  participating cities differs in the types of new knowledge they will deliver, the exhibitions they will develop, and the artistic creations they will produce, all within the different time and spatial environments and the cultural effects of the ongoing pandemic. These various approaches and the degree of immersive public experiences are also important considerations when comparing and analysing different visual and curatorial strategies to reveal a city’s unique cultural value within the world.
          </p>
          <p>
            In <i>CITYA</i>, we will also explore how the ideas of project / exhibition making can expand contemporary art’s boundaries in terms of art practitioners. This international exchange project can cultivate empathy through the similarities and differences of cities in both their online and physical existences.
          </p>
          <p>
            The project comprises of five deliverables - Curatorial platform, creations of artworks, online and physical exhibitions/screenings, Online/ Physical Talks or Presentations, academic texts—will offer an in-depth analysis on the five cities’ projects/exhibition makings art scene in terms of value impartation and interdisciplinary methods of display, time, and spatiality. Large number of audiences will be attracted to <i>CITYA</i> due to the global accessibility of this unique platform.
          </p>
          <p>
            This project reveals new artistic, humanistic, and cultural experiences through this unique platform with contemporary art and cultural innovation for elaborating the possible future development of Art. This project creates an alternative horizon of digital curation as a way of broadening awareness of the cultural landscape of these cities through contemporary art and exhibition making in the virtual art scene.
          </p>
          <!-- Footnotes -->
          <small>
            <div class="mb-1 text-secondary">
              <sup>1</sup> Whybrow Nicolas, Art and the City (I.B. Taurius - London, New York, 2011, p.15)
            </div>
            <div class="mb-1 text-secondary">
              <sup>2</sup> Curators include Patricia Roldewald, Adriel Luis, Jenny Balisle and Janet Fong
            </div>
            <div class="mb-1 text-secondary">
              <sup>3</sup> Ibid.p.17
            </div>
            <div class="mb-1 text-secondary">
              <sup>4</sup> Hui Yuk, Space and Place: Remarks on a Digital Chronotopology in the book edited by Siegfried Zielinski, Charles Merewether, Art in the 21st Century: Reflections & Provocations (Osage publications, Hong Kong, p.47)
            </div>
            <div class="mb-1 text-secondary">
              <sup>5</sup> Vince Dziekan (2012) Virtuality and the Art of Exhibition: Curatorial Design for the Multimedial Museum, Intellect Books, Bristol, p. 57
            </div>
          </small>
        </div>
      </div>
    </div>
    <!-- about citya - our mission -->

    <!-- presenters -->
    <AboutPresenters>
    </AboutPresenters>
    <!-- presenters -->

    <!-- programmes -->
    <div
      id="programmesFadeUp"
    >
      <div
        data-aos="fade-up"
        data-aos-anchor="#programmesFadeUp"
        data-aos-duration="500"
      >
        <h2 id="programmes" class="text-uppercase text-center mt-5">
          programmes
        </h2>
        <div class="row">
          <div class="col-12 col-lg-6 col-xl-4 offset-0 offset-lg-3 offset-xl-4">
            <h3 class="text-uppercase text-center mt-4 mt-lg-5">
              exhibition
            </h3>
            <h4 class="text-uppercase text-center">
              e-xhibition and phy-xhibition
            </h4>
            <router-link
              class="btn btn-light w-100 mb-2"
              v-for="city in city"
              :key="city.City"
              :to="'/city/' + city.Slug"
            >
              <div class="wrapper d-flex justify-content-between">
                <div class="d-flex text-uppercase">
                  <span class="h4 mb-0">explore |&nbsp;</span>
                  <div class="h4 mb-0">{{ city.City }}</div>
                </div>
                <img src="../../assets/icon/right-arrow-black.svg" alt="" class="link-icon ms-2" />
              </div>
            </router-link>
            <h4 class="text-uppercase text-center mt-3">
              global screening
            </h4>
            <router-link
              class="btn btn-light w-100 mb-2"
              to="/global-screening"
            >
              <div class="wrapper d-flex justify-content-between">
                <div class="d-flex text-uppercase">
                  <span class="h4 mb-0">explore |&nbsp;</span>
                  <div class="h4 mb-0">global screening</div>
                </div>
                <img src="../../assets/icon/right-arrow-black.svg" alt="" class="link-icon ms-2" />
              </div>
            </router-link>
          </div>
          <div class="col-12 col-lg-6 offset-0 offset-lg-3">
            <h4 class="text-uppercase text-center mt-3">
              online residency
            </h4>
            <p class="en">
              Organised by Videotage, curated by Kyle Chung
            </p>
            <p class="en">
              The Leave Your Body residency invites art professionals across the contemporary art landscape in Hong Kong and beyond to leave their mark in the virtual site of residency. Recreated in one-to-one scale in the sandbox game Minecraft, the physical art spaces of the hosting art organisations have been transformed into a gaming platform for the virtual residency participants to explore, play and create. Each participant engages in a site-inspired, site-specific creative process to build in the virtual site inherited from their predecessors, constructing an ever-growing artistic realm.
            </p>
            <p class="en">
              In summer 2020, the first edition of Leave Your Body was built upon the slaughterhouse-turned heritage site Cattle Depot Artist Village, with Hong Kong-based participants including Cosmin Costinas, Frog King, Mak Ying Tong 2, and Peter Nelson.
            </p>
            <p class="en">
              The second edition of Leave Your Body features Hong Kong artist Angela Su and UK artist Clifford Sage as part of the programme Both Sides Now 6 in collaboration with videoclub in the UK.
            </p>
            <p class="en">
              The third edition of Leave Your Body has rebuilt the vanished space of Taipei Contemporary Art Center in July 2021, while the fourth edition will take place back in Hong Kong in collaboration again with Peter Nelson as a part of CITYA in September 2021.
            </p>
            <p class="en">
              Leave Your Body is an ongoing artistic platform. The creative process in the residencies is documented and distributed on the social media platforms of Videotage and other hosting organisations. The Leave Your Body Minecraft world is available online for viewing for Minecraft users on request.
            </p>
            <h4 class="text-uppercase text-center mt-3">
              panel discussion
            </h4>
            <p class="en">
              On Friday October 1st from 6-7:30pm PST, the Art and Design Department of University of California, Berkeley Extension will be hosting a CITYA panel discussion. Panelists include Anastasia Meadors (UC Berkeley Extension Program Director, Art and Design) along with curators Janet Fong, Jenny E. Balisle, and Juliana Chan. Featured CITYA artists include Kevin Tracy, Manjula Dean, Eden Knutilla, Robin López, Tom O’Dea, Kalen Wing Ki, Lee, Li Lin and Lampo Leong.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- programmes -->

    <!-- leaflet pdf download -->
    <h2 class="text-uppercase text-center mt-5">
      citya leaflet
    </h2>
    <div class="row mt-3 mb-footer">
      <div class="col-12 col-lg-4 offset-0 offset-lg-4">
        <a
          :href="`${(publicPath)}documents/CITYA_leaflet.pdf`"
          target="_blank"
          class="btn btn-light w-100"
        >
          <div class="wrapper d-flex justify-content-between">
            <div class="d-flex text-uppercase">
              <span class="h4 mb-0">explore |&nbsp;</span>
              <div class="h4 mb-0">citya leaflet</div>
            </div>
            <img src="../../assets/icon/right-arrow-black.svg" alt="" class="link-icon ms-2" />
          </div>
        </a>
      </div>
    </div>
    <!-- leaflet pdf download -->
  </div>
  <!-- Footer -->
  <Footer
    :links="footerLinks"
    :bottom="scrollToBottom"
  ></Footer>
  <!-- Footer -->
</template>

<script>
import gql from 'graphql-tag';
import gsap from 'gsap';
import AOS from 'aos';
import Loading from '@/components/front/LoadingGeneral.vue';
import TopNav from '@/components/front/TopNav.vue';
import AboutPresenters from '@/components/front/AboutPresenters.vue';
import Footer from '@/components/front/Footer.vue';
import 'aos/dist/aos.css';

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      apolloLoad: false,
      // presenterCollapse0: '',
      // presenterCollapse1: '',
      // presenterCollapse2: '',
      // presenterCollapse3: '',
      // presenterCollapse4: '',
      // presenterCollapse5: '',
      // presenterCollapse6: '',
      // presenterCollapse7: '',
      // selectedIndex: '',
      // presenterOpened: false,
      // presenter: [
      //   {
      //     Presenter: 'Academy of Visual Arts, Hong Kong Baptist University, Hong Kong',
      //     Slug: '',
      //     ContentEng: 'The',
      //     ContentChin: 'The',
      //   },
      //   {
      //     Presenter: 'Videotage, Hong Kong',
      //     Slug: '',
      //     ContentEng: 'The',
      //     ContentChin: 'The',
      //   },
      //   {
      //     Presenter: '1a Space, Hong Kong',
      //     Slug: '',
      //     ContentEng: 'The',
      //     ContentChin: 'The',
      //   },
      //   {
      //     Presenter: 'Centre for Arts and Design, University of Macau, Macao',
      //     Slug: '',
      //     ContentEng: 'The',
      //     ContentChin: 'The',
      //   },
      //   {
      // eslint-disable-next-line max-len
      //     Presenter: 'Virtual Lab for Art Museum, School of Arts Administration and Education, Central Academy of Fine Arts, Beijing',
      //     Slug: '',
      //     ContentEng: 'The',
      //     ContentChin: 'The',
      //   },
      //   {
      //     Presenter: 'Department of Art and Design, University of California, Berkeley Extension, San Francisco',
      //     Slug: '',
      //     ContentEng: 'The',
      //     ContentChin: 'The',
      //   },
      //   {
      //     Presenter: 'Estonian Academy of Arts, Tallinn',
      //     Slug: '',
      //     ContentEng: 'The',
      //     ContentChin: 'The',
      //   },
      //   {
      //     Presenter: 'Fine Arts Academy of Rome',
      //     Slug: '',
      //     ContentEng: 'The',
      //     ContentChin: 'The',
      //   },
      // ],
      footerLinks: [
        {
          Text: 'BACK TO HOME',
          Link: '/',
        },
        {
          Text: 'OUR MISSION',
          Link: '/about#about-citya',
        },
        {
          Text: 'presenters',
          Link: '/about#presenters',
        },
        {
          Text: 'PROGRAMMES',
          Link: '/about#programmes',
        },
      ],
      scrollToBottom: false,
    };
  },
  components: {
    Loading,
    TopNav,
    AboutPresenters,
    Footer,
  },
  apollo: {
    // presenter: {
    //   query: gql`
    //     query Presenter {
    //       presenters {
    //         Presenter
    //         Slug
    //         ContentEng
    //         ContentChin
    //         Website
    //       }
    //     }
    //   `,
    //   update(data) {
    //     return data.presenters;
    //   },
    //   error(error) {
    //     console.error("We've got an error!", error);
    //   },
    // },
    city: {
      query: gql`
        query City {
          cities {
            City
            Slug
          }
        }
      `,
      update(data) {
        return data.cities;
      },
      error(error) {
        console.error("We've got an error!", error);
      },
      loadingKey: 'loadingQueriesCount',
      // watchLoading will be called whenever the loading state changes
      // eslint-disable-next-line no-unused-vars
      watchLoading(isLoading, countModifier) {
        // isLoading is a boolean
        // countModifier is either 1 or -1

        this.apolloLoad = false;
        if (!isLoading) {
          this.apolloLoad = true;
          console.log('load complete');
        }
      },
    },
  },
  computed: {
    load() {
      console.log(`apollo status: ${this.apolloLoad}`);
      AOS.init({ once: false, disable: 'phone' });
      AOS.refresh();
      return this.apolloLoad;
    },
  },
  methods: {
    /**
     * Page Load effects
     */
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 1,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
      setTimeout(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
      }, 1000);
    },
    /**
     * Main methods
     */
    onScroll() {
      const windowY = window.scrollY;
      const intViewportHeight = window.innerHeight;
      if (windowY + intViewportHeight >= document.documentElement.offsetHeight) {
        this.scrollToBottom = true;
      } else {
        this.scrollToBottom = false;
      }
    },
  },
  mounted() {
    console.clear();
    // enable black background
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-primary');
    window.addEventListener('scroll', this.onScroll);
  },
  unmounted() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-primary');
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>
